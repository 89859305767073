import Landing from "./components/Landing";
function App() {
  return (
    <div className="App">
      <Landing></Landing>
    </div>
  );
}

export default App;
